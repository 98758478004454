import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import store from '@/store'
export default new Vuetify({
  icons: {
    iconfont: 'mdi' || 'fa' || 'fa4' // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: store.getters['auth/activeBusinessBrandingAndLogo'].primary,
        secondary: store.getters['auth/activeBusinessBrandingAndLogo'].secondary,
        accent: store.getters['auth/activeBusinessBrandingAndLogo'].accent,
        error: '#D0021B',
        warning: '#FFC200',
        info: '#3498DB',
        success: '#5CB85C',
        black: '#000000',
        bloodred: '#af371f',
        darkGrey: '#4b4b4b',
        background: '#FAFAFA'
      }
    }
  }
})
Vue.use(Vuetify)
