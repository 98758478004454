/* eslint-disable no-unused-vars */
import Vue from 'vue'

// styles
import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/styles/main.scss'
import VueConfetti from 'vue-confetti'
// essentials
import App from './App.vue'
import router from './router'
import store from './store'

// plugins
import './plugins/global.mixins'
import MiddlewarePlugin from 'vue-router-middleware-plugin'
import '../node_modules/flag-icon-css/sass/flag-icon.scss'
import SweetAlert from './plugins/sweetalert'
// import './plugins/middlewares'
import vuetify from './plugins/vuetify'
import _ from 'lodash'

Vue.use(VueConfetti)

Object.defineProperty(Vue.prototype, '$_', { value: _ })

Vue.config.productionTip = false
Vue.use(MiddlewarePlugin, router)
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  data() {
    return {
      spinners: {
        main: false
      },
      resourcePicker: {
        status: false,
        resource: null
      },
      hideLocations: false
    }
  }
}).$mount('#app')
