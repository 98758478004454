/* eslint-disable camelcase */
import { AuthAPI } from '@/clients/auth/auth.api'

import {
  SET_USER,
  SET_BUSINESSES_LIST,
  SET_ACTIVE_BUSINESS,
  SET_ACTIVE_BUSINESS_BRANDING,
  SET_LOCATIONS,
  CLEAR_AUTH_STATE
} from '@/store/mutation-types'

export default {
  async loginUser({ commit }, loginData) {
    const { data: { payload } } = await AuthAPI.login(loginData)
    const { user } = payload
    user.token = payload['auth-token']
    commit(SET_USER, user)
    return payload
  },
  async loginWithToken({ commit }, token) {
    const { data: { payload } } = await AuthAPI.loginWithToken(token)
    const { user } = payload
    user.token = token
    commit(SET_USER, user)
    return payload
  },
  async setUserBusinessesList({ commit }) {
    const { data: { payload } } = await AuthAPI.getUserBusinesses()
    commit(SET_BUSINESSES_LIST, payload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    return payload
  },
  async setUserBusinessesListNotLoggedIn ({ commit }, token) {
    const { data: { payload } } = await AuthAPI.getUserBusinessesNotLoggedIn(token)
    commit(SET_BUSINESSES_LIST, payload.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    return payload
  },
  async setActiveBusiness ({ commit }, business) {
    const { data: { payload } } = await AuthAPI.setActiveBusiness(business.id)
    commit(SET_ACTIVE_BUSINESS, business)
    return payload
  },
  async setActiveBusinessBranding({ commit }, businessId) {
    const { data: { payload } } = await AuthAPI.getBusinessById(businessId, true)
    commit(SET_ACTIVE_BUSINESS_BRANDING, payload)
  },

  async setUserLocationList({ commit }) {
    const { data: { payload } } = await AuthAPI.getUserLocations()
    commit(SET_LOCATIONS, payload)
    return payload
  },

  async setUsersforLocation({ commit }, { locationId, page, limit }) {
    const { data: { payload } } = await AuthAPI.getUsersforLoacation(locationId, page, limit)
    return payload
  },

  async setUserToLocation({ commit }, { userId, data }) {
    const payload = await AuthAPI.addUserToLocation(userId, data)
    return payload.data
  },

  async removeUserFromLocation({ commit }, { userId, data }) {
    const payload = await AuthAPI.deleteUserFromLocation(userId, data)
    return payload.data
  },

  async clearState({ commit }) {
    commit(CLEAR_AUTH_STATE)
  }
}
