import { AUTH_URL, AUTH_API_TOKEN, authToken } from '../env'
import axios from '../_client'
import { EventBus } from '@/event-bus'
const authAxiosInstaceV1 = axios.create({
  baseURL: `${AUTH_URL}/api/v1`,
  headers: {
    'api-token': AUTH_API_TOKEN
  }
})
const authAxiosInstanceV2 = axios.create({
  baseURL: `${AUTH_URL}/api/v2`,
  headers: {
    'api-token': AUTH_API_TOKEN
  }
})

authAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status === 401 || error.response.status === 403) {
    EventBus.$emit('unauthorized')
  }
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})

authAxiosInstanceV2.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status === 401 || error.response.status === 403) {
    EventBus.$emit('unauthorized')
  }
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})
export const AuthAPI = {

  /**
   * Login user with Email and Password
   * @param {string} [data={ email: '', password: '' }]
   * @returns {Promise}
   */
  login(data = { email: '', password: '' }) {
    return authAxiosInstaceV1({
      method: 'post',
      data: data,
      url: `/auth/login/local`,
      headers: {
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  /**
   *
   * Login user with userToken
   * @param {*} token
   * @returns
   */
  loginWithToken(token) {
    return authAxiosInstaceV1({
      method: 'get',
      url: `/users/profile`,
      headers: {
        'auth-token': token,
        'api-token': AUTH_API_TOKEN
      }
    })
  },

  /**
   * Call to get list of user locations
   *
   * @returns {Promise}
   */
  getUserLocations() {
    return authAxiosInstaceV1({
      method: 'get',
      url: '/locations/',
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },

  /**
   * Call to get list of user businesses
   *
   * @returns {Promise}
   */
  getUserBusinesses() {
    return authAxiosInstaceV1({
      method: 'get',
      url: '/users/businesses',
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },

  /**
   * Call to get list of user businesses when not logged in
   *
   * @returns {Promise}
   */
  getUserBusinessesNotLoggedIn(token) {
    return authAxiosInstaceV1({
      method: 'get',
      url: '/users/businesses',
      headers: {
        'auth-token': token,
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  /**
   * Call to set users active business
   *
   * @param {*} businessId
   * @returns {Promise}
   */
  setActiveBusiness(businessId) {
    return authAxiosInstaceV1({
      method: 'patch',
      url: `/users/businesses/${businessId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  /**
   *  Call to get a business by id
   *
   * @returns {Promise}
   */
  getBusinessById(businessId, withBranding = false) {
    return authAxiosInstaceV1({
      method: 'get',
      url: `/businesses/${businessId}?withBranding=${withBranding}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  getUsersForABusiness(businessId) {
    return authAxiosInstaceV1({
      method: 'get',
      url: `/businesses/${businessId}/users?inactive=true`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  updateUserById(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/users/${userId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  toggleCorporateUser(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/users/${userId}/businesses/corporate`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  toggleAdminUser(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/users/${userId}/businesses/admin`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  toggleUserStatus(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/users/${userId}/businesses/status`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  inviteANewUser(data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/invitations`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  getInvitationDetails(id) {
    return authAxiosInstaceV1({
      method: 'get',
      url: `/invitations/${id}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  resendInvitation(id) {
    return authAxiosInstaceV1({
      method: 'patch',
      url: `/invitations/${id}/resend`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  checkIfUserExist(data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/invitations/user_check`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  inviteExistingUser(userId, data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/invitations/users/${userId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  getAllOpenInvitations(expired = false) {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/invitations?expired=${expired}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  getInvitationById(id) {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/invitations/${id}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  preAssignRoles(invId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/invitations/${invId}/roles`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  changePassword(data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/passwords`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  changePasswordByUserId(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/users/${userId}/passwords`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  cancelAnInvitation(invId) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/invitations/${invId}/cancel`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  updateBusinessBranding(businessId, data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/businesses/${businessId}/branding`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },

  createBusiness(data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/businesses`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },

  getAllBusinesses(data) {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/businesses`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      params: {
        withBranding: data ? data.withBranding : false
      }
    })
  },
  getBusinessSettings(businessId) {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/businesses/${businessId}/settings`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  updateBusinessSettings(businessId, settingId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/businesses/${businessId}/settings/${settingId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  saveBusinessSettingsLogo(data) {
    return authAxiosInstanceV2({
      method: 'POST',
      url: `/assets/upload`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
    // post request with body: {"file_name": "something.png"}
  },
  getUsersForALocation(locationId, page, limit, noPagination = false) {
    return authAxiosInstaceV1({
      method: 'get',
      url: `/locations/${locationId}/users`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      params: {
        fullData: noPagination
      }
    })
  },

  addUserToLocation(userId, data) {
    return authAxiosInstaceV1({
      method: 'post',
      url: `/locations/users/${userId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },

  deleteUserFromLocation(userId, data) {
    return authAxiosInstaceV1({
      method: 'delete',
      url: `/locations/users/${userId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },

  logout() {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/users/logout`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  getUserSettings(userId) {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `users/${userId}/settings`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  updateUserSettings(userId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `users/${userId}/settings/`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  joinBusiness(bizId) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `businesses/${bizId}/join`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  getLogs({ startDate = null, endDate = null, page = 1, limit = 25, data }) {
    if (startDate && endDate) {
      return authAxiosInstaceV1({
        method: 'POST',
        url: `auth/login/logs?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`,
        headers: {
          'auth-token': authToken(),
          'api-token': AUTH_API_TOKEN
        }
      })
    }
    return authAxiosInstaceV1({
      method: 'POST',
      url: `auth/login/logs?page=${page}&limit=${limit}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  deleteBusiness(businessId) {
    return authAxiosInstaceV1({
      method: 'DELETE',
      url: `/businesses/${businessId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  generateApiTokens(appId, data) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/api_tokens/${appId}/token`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  getAllApplications() {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/api_tokens/applications`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  getAllApiTokens() {
    return authAxiosInstaceV1({
      method: 'GET',
      url: `/api_tokens`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      }
    })
  },
  searchUserWithinBusiness(data, businessId) {
    return authAxiosInstaceV1({
      method: 'POST',
      url: `/businesses/${businessId}/users/search`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  },
  updateBusiness(businessId, data) {
    return authAxiosInstaceV1({
      method: 'PATCH',
      url: `/businesses/${businessId}`,
      headers: {
        'auth-token': authToken(),
        'api-token': AUTH_API_TOKEN
      },
      data
    })
  }
}
