import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from '@/store/index'

import 'sweetalert2/dist/sweetalert2.min.css'
const options = {
  confirmButtonColor: store.getters['auth/activeBusinessBrandingAndLogo'].primary || '#41b882',
  cancelButtonColor: '#ff7674'
}

Vue.use(VueSweetalert2, options)
