import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// state
import Snackbar from '@/components/custom-components/snackbar/snackbar.state'
import auth from './modules/auth/index'
import applications from './modules/applications/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: { Snackbar, auth, applications }
})

export default store
