<template>
  <v-card flat tile class="heading-offset-wrapper">
    <div class="bg-white">
      <v-card color="primary mx-5" :class="innerStyle">
        <v-card-title class="px-3">
          <v-layout row wrap>
            <v-flex :class="hasActionSlot ? 'xs12 sm12 md9 lg9' : 'xs12 sm12 md12 lg12'">
              <h2 :class="titleClass" v-if="title" v-text="title"></h2>
              <p v-if="subtitle" class="white--text mb-0" v-text="subtitle"></p>
            </v-flex>
            <v-flex class="text-md-right text-xs-left xs12 md3 lg3" v-if="hasActionSlot">
              <slot name="action"></slot>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </div>
  </v-card>
</template>

<script lang="js">
export default {
  props: ['title', 'titleClass', 'subtitle'],
  computed: {
    hasActionSlot () {
      return !!this.$slots.action
    },
    innerStyle () {
      if (this.subtitle) {
        return 'elevation-8 offset-heading rounded heading-offset-40'
      } else {
        return 'elevation-8 offset-heading rounded heading-offset-30'
      }
    }
  }
}
</script>
<style scoped>
.heading-offset-wrapper {
  position: relative;
  margin-top: 60px;
}
.heading-offset-30 {
  position: relative;
  top: -31px;
}
.heading-offset-40 {
  position: relative;
  top: -42px;
}
</style>
