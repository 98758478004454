/* eslint-disable camelcase */
import { SET_APPLICATIONS } from '@/store/mutation-types'
import { ApplicationsAPI } from '@/clients/permissions/apps'

export default {
  async setApplications({ commit }, businessId) {
    let response
    try {
      response = await ApplicationsAPI.getAppsForAbusiness(businessId)
    } catch (error) {
      commit(SET_APPLICATIONS, [])
      return error
    }
    commit(SET_APPLICATIONS, response.data.payload)
    return response.data.payload
  }
}
