// auth middle ware to check if the user is logged in
import store from '@/store/index'
export function BackToLogin ({ to, from, redirect }) {
  if (!store.getters['auth/user']) {
    redirect('/')
  }
}
export function BackToDashboard ({ to, from, redirect }) {
  console.log(to)
  if (store.getters['auth/user'] && !to.query['token']) {
    redirect('/applications')
  }
}
