const defaultState = () => {
  return {
    user: '',
    locationsList: [],
    selectedLocations: [],
    businessesList: [],
    activeBusiness: '',
    activeBusinessBranding: ''
  }
}

export default defaultState
