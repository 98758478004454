import { PERMISSIONS_URL, PERMISSIONS_API_TOKEN, authToken } from '../env'
import axios from '../_client'
import { EventBus } from '@/event-bus'

const permissionsAxiosInstance = axios.create({
  baseURL: `${PERMISSIONS_URL}/api/v1`,
  headers: {
    'api-token': PERMISSIONS_API_TOKEN
  }
})

permissionsAxiosInstance.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  } if (response.data.easter_egg) {
    EventBus.$emit('show-easter-egg', response.data.easter_egg)
  }
  return response
}, function (error) {
  if (error.response.status !== 404) {
    EventBus.$emit('show-error-modal', error)
  } else if (error.response.status === 404) {
    EventBus.$emit('show-error-snackbar', error)
  }
  return Promise.reject(error)
})

export const ApplicationsAPI = {
  getAllApps() {
    return permissionsAxiosInstance({
      method: 'get',
      url: '/apps',
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAllSuperadminApps() {
    return permissionsAxiosInstance({
      method: 'get',
      url: '/apps?all=true',
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAllResourcesForAnApp(appId, permissions = true, adminFormat = false) {
    return permissionsAxiosInstance({
      method: 'get',
      url: `apps/${appId}/resources?permissions=${permissions}&admin_format=${adminFormat}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAllRolesForAnApplication(appId) {
    return permissionsAxiosInstance({
      method: 'get',
      url: `/apps/${appId}/roles`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  updateAppResourceById(appId, appResourceId, data) {
    return permissionsAxiosInstance({
      method: 'patch',
      url: `/apps/${appId}/resources/${appResourceId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  createApplicationResource(appId, data) {
    return permissionsAxiosInstance({
      method: 'POST',
      url: `/apps/${appId}/resources`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  deleteAnApplicationResource(appId, resouceId) {
    return permissionsAxiosInstance({
      method: 'DELETE',
      url: `/apps/${appId}/resources/${resouceId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  create(data) {
    return permissionsAxiosInstance({
      method: 'POST',
      url: `/apps`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getPermissionsForAnApp(appId) {
    return permissionsAxiosInstance({
      method: 'get',
      url: `/apps/${appId}/resources?admin_format=true`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  addAnAppToBusiness(data, businessId) {
    return permissionsAxiosInstance({
      method: 'POST',
      url: `/businesses/${businessId}/apps`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getAppsForAbusiness(businessId) {
    return permissionsAxiosInstance({
      method: 'GET',
      url: `/businesses/${businessId}/apps`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getAdminAppsForBusiness(businessId) {
    return permissionsAxiosInstance({
      method: 'GET',
      url: `admins/businesses/${businessId}/apps`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  getBusinessAppDetails(businessAppId) {
    return permissionsAxiosInstance({
      method: 'GET',
      url: `/admins/apps/businesses/${businessAppId}`,
      headers: {
        'auth-token': authToken()
      }
    })
  },
  updateBusinessAppDetails(businessUuid, businessAppId, data) {
    return permissionsAxiosInstance({
      method: 'PATCH',
      url: `/businesses/${businessUuid}/apps/${businessAppId}`,
      headers: {
        'auth-token': authToken()
      },
      data
    })
  },
  getAppProviders(appId) {
    return permissionsAxiosInstance({
      method: 'GET',
      url: `/apps/${appId}/providers`,
      headers: {
        'auth-token': authToken()
      }
    })
  }
}
