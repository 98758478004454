import getters from './getters'
import mutations from './mutations'
import actions from './actions'
export const initState = function () {

}
export default {
  namespaced: true,
  state: require('./state.json'),
  getters,
  mutations,
  actions
}
