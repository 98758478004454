<template>
  <div id="app">
    <v-app>
      <template v-if="displayNavs">
        <LeftNav :open="leftNav"></LeftNav>
        <TopNav @right-nav-toggle="rightNav = !rightNav" @left-nav-toggle="leftNav = !leftNav"></TopNav>
        <RightNav :open="rightNav"></RightNav>
      </template>
      <v-main class="background">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
      <spinner></spinner>
      <Snackbar></Snackbar>
      <v-overlay
        @keydown.esc="closeEasterEgg()"
        opacity="0.75"
        :value="easterEgg.overlay"
        z-index="74"
      >
        <v-layout row wrap justify-center>
          <v-col cols="10" md="8">
            <v-layout row wrap justify-center mt-7>
              <p class="display-2 font-weight-bold">{{easterEgg.title}}</p>
            </v-layout>
            <v-img :src="easterEgg.image" contain aspect-ratio="1.6" width="100vw" height="90vh">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-btn
            small
            @click="closeEasterEgg"
            class="mt-11"
            absolute
            fab
            right
            top
            color="darkGrey"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-layout>
      </v-overlay>
      <v-dialog
        v-if="$root.resourcePicker.status"
        v-model="$root.resourcePicker.status"
        fullscreen
        persistent
        transition="dialog-transition"
      >
        <v-card>
          <ResourcePicker
            @resources-selected="resourceSelected($event)"
            @closeModal="$root.resourcePicker.status = false"
            :auth-token="vg_user.token"
            :api-token="resourcesApiToken"
          />
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    TopNav: () => import('@/components/layout/TopNav'),
    LeftNav: () => import('@/components/layout/LeftNav'),
    RightNav: () => import('@/components/layout/RightNav'),
    Spinner: () => import('@/components/custom-components/BlockSpinner'),
    Snackbar: () => import('@/components/custom-components/snackbar/snackbar.vue'),
    ResourcePicker: () => import('@/plugins/unoapp-resource-picker/app.resource.picker')
  },
  data() {
    return {
      leftNav: true,
      rightNav: false,
      easterEgg: {
        overlay: false,
        title: 'Happy DAY!',
        image: 'https://s3.amazonaws.com/images.seroundtable.com/google-my-business-photos-animated-1570448884.gif'
      }
    }
  },
  created() {
    EventBus.$on('show-easter-egg', (easterEgg) => {
      this.openEasterEgg(easterEgg)
    })
    EventBus.$on('unauthorized', () => {
      this.va_clearAuthState()
      this.$router.push({ name: 'Login' })
    })
    EventBus.$on('show-error-modal', (error) => {
      this.swal({
        title: 'Error',
        text: error.response.data.msg,
        type: 'error'
      })
      this.spinner(false)
    })
    EventBus.$on('show-error-snackbar', (error) => {
      this.snackbar({ text: error.response.data.msg, timeout: 5000 })
      this.spinner(false)
    })
  },
  methods: {
    ...mapActions({
      va_clearAuthState: 'auth/clearState'
    }),
    resourceSelected(resource) {
      this.$root.resourcePicker.resource = resource
      this.$root.$emit('resource-selected', resource)
      this.$root.resourcePicker.status = false
    },
    closeEasterEgg() {
      this.easterEgg = {
        overlay: false,
        title: '',
        image: ''
      }
      this.$confetti.stop()
    },
    openEasterEgg(easter) {
      this.easterEgg.title = easter.title
      this.easterEgg.image = easter.image_url
      if (easter.confetti) {
        this.$confetti.start({ particlesPerFrame: 0.75, defaultDropRate: 9 })
      }
      this.easterEgg.overlay = true
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user'
    }),
    isLoggedIn() {
      let user = this.vg_user
      if (!user || user === undefined || user === '') {
        return false
      }
      return true
    },
    displayNavs() {
      return this.isLoggedIn && !this.isLoginRoute
    },
    isLoginRoute() {
      return this.$route.name === 'Login'
    },
    resourcesApiToken() {
      return process.env.VUE_APP_UNOAPP_RESOURCES_API_TOKEN
    }
  }
}
</script>
<style>
#confetti-canvas {
  z-index: 100;
}
</style>
