// auth
export const SET_USER = 'SET_USER'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const SET_BUSINESSES_LIST = 'SET_BUSINESSES_LIST'
export const SET_ACTIVE_BUSINESS = 'SET_ACTIVE_BUSINESS'
export const SET_ACTIVE_BUSINESS_BRANDING = 'SET_ACTIVE_BUSINESS_BRANDING'
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE'
export const SET_BASIC_USER_INFO = 'SET_BASIC_USER_INFO'

// applications

export const SET_APPLICATIONS = 'SET_APPLICATIONS'
