import axios from 'axios'
const LOCATIONS_IDENTIFIER = process.env.VUE_APP_LOCATIONS_IDENTIFIER
export default {
  applications: (state) => state.applications,
  isLocationsEnabled: (state) => state.applications.find(i => i.identifier === LOCATIONS_IDENTIFIER),
  locationsAxiosInstance: (state) => {
    let locationConfig = state.applications.find(i => i.identifier === LOCATIONS_IDENTIFIER)
    if (locationConfig) {
      console.log(locationConfig)
      return axios.create({
        baseURL: locationConfig.app_details.api_url,
        headers: locationConfig.app_details.api_headers
      })
    }
  }
}
