import {
  SET_USER,
  SET_LOCATIONS,
  SET_SELECTED_LOCATION,
  SET_BUSINESSES_LIST,
  SET_ACTIVE_BUSINESS,
  SET_ACTIVE_BUSINESS_BRANDING,
  CLEAR_AUTH_STATE,
  SET_BASIC_USER_INFO
} from '@/store/mutation-types'
import defaultState from './defaultState.js'

export default {
  [SET_USER](state, payload) {
    state.user = payload
  },
  [SET_LOCATIONS](state, payload) {
    state.locationsList = payload
  },
  [SET_SELECTED_LOCATION](state, payload) {
    state.selectedLocations.push(payload)
  },
  [SET_BUSINESSES_LIST](state, data) {
    state.businessesList = data
  },
  [SET_ACTIVE_BUSINESS](state, business) {
    state.activeBusiness = business
  },
  [SET_ACTIVE_BUSINESS_BRANDING](state, branding) {
    state.activeBusinessBranding = branding
  },
  [SET_BASIC_USER_INFO](state, user) {
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
    state.user.email = user.email
  },
  [CLEAR_AUTH_STATE](state) {
    // state.replaceState({})
    Object.assign(state, defaultState())
  }
}
