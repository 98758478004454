import store from '@/store'

export default {
  methods: {
    snackbar({ text = '', open = true, timeout = 3000, color = 'grey darken-2', x = null, y = 'bottom', icon = null }) {
      store.dispatch('Snackbar/snackbar', { text, open, timeout, color, x, y, icon })
    },
    spinner(state) {
      this.$root.spinners.main = state
    },
    getSpinnerState() {
      return this.$root.spinners.main
    },
    openResourcePicker() {
      this.$root.resourcePicker.status = true
    },
    swal({ type = 'success', title, text, cancelButtonText, confirmButtonText = 'Okay', timer }) {
      return this.$swal({
        title: title,
        text: text,
        icon: type,
        showCancelButton: !!cancelButtonText,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        timer
      })
    },
    _isAdminUser() {
      let activeBusiness = store.getters['auth/activeBusiness']
      if (activeBusiness.UserBusiness) {
        return activeBusiness.UserBusiness.admin_access
      }
      return activeBusiness.admin_access ? activeBusiness.admin_access : false
    },
    _isCorporateUser() {
      let activeBusiness = store.getters['auth/activeBusiness']
      if (activeBusiness.UserBusiness) {
        // when logged in using auth - locally
        return activeBusiness.UserBusiness.is_corporate
      }
      // when logged in using token from the url and getting active business using the profile call
      return activeBusiness.is_corporate ? activeBusiness.is_corporate : false
    },
    _isUnoappUser() {
      return store.getters['auth/user'].type === 'unoapp'
    },
    _isSuperUser() {
      return store.getters['auth/user'].type === 'super'
    },
    _is50c() {
      return process.env.VUE_APP_AUTH_URL.toLowerCase().indexOf('50c') > -1
    }
  }
}
