import Vue from 'vue'
import Router from 'vue-router'
import { BackToLogin, BackToDashboard } from './middlewares/middlewares'
/* Global Components */
/* -----Offset Heading----- */
import OffsetHeading from '@/components/custom-components/OffsetHeading'
Vue.component('offset-heading', OffsetHeading)
Vue.component('offset-block', () => import('@/components/custom-components/OffsetBlock'))
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Login/Login.vue'),
      name: 'Login',
      meta: {
        middleware: BackToDashboard,
        nav: {
          display: false,
          name: 'Login'
        }
      }
    },
    {
      path: '/login',
      component: () => import('@/views/Login/Login.vue'),
      meta: {
        middleware: BackToDashboard,
        nav: {
          display: false,
          name: 'Login'
        }
      }
    },
    {
      path: '/applications/edit/:id',
      component: () => import('@/views/Applications/EditApplication/EditApplication.vue'),
      name: 'Edit Application',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: false,
          name: 'Edit Application',
          icon: 'apps',
          showBackButton: true
        }
      }
    },
    {
      path: '/applications',
      component: () => import('@/views/Applications/Applications.vue'),
      name: 'Applications',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: true,
          name: 'Applications',
          icon: 'apps'
        }
      }
    },
    {
      path: '/users',
      component: () => import('@/views/Users/Users.vue'),
      name: 'Users',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: true,
          name: 'Users',
          icon: 'fas fa-users'
        }
      },
      children: [
        {
          path: 'all',
          component: () => import('@/views/Users/AllUsers.vue'),
          name: 'All Users',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'All Users',
              icon: 'fas fa-user-cog'
            }
          }
        },
        {
          path: 'open-invitations',
          component: () => import('@/views/Users/OpenInvitations.vue'),
          name: 'Open Invitations',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Invitations',
              icon: 'fas fa-user-cog'
            }
          }
        },
        {
          path: 'expired-invitations',
          component: () => import('@/views/Users/ExpiredInvitations.vue'),
          name: 'Expired Invitations',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Expired Invitations',
              icon: 'fas fa-user-cog'
            }
          }
        }
      ]
    },
    {
      path: '/roles',
      component: () => import('@/views/Roles/Roles.vue'),
      name: 'Roles',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: true,
          name: 'Roles',
          icon: 'fas fa-user-cog'
        }
      }
    },
    {
      path: '/business-settings',
      component: () => import('@/views/BusinessSettings/BusinessSettings.vue'),
      name: 'Business Settings',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: false,
          name: 'Business Settings',
          icon: 'fas fa-user-cog'
        }
      }
    },
    {
      path: '/admin/',
      component: () => import('@/views/Admin/settings.vue'),
      name: 'Admin',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: false,
          name: 'Admin',
          icon: 'fas fa-user-cog'
        }
      },
      children: [
        {
          path: 'businesses',
          component: () => import('@/views/Admin/businesses.vue'),
          name: 'Businesses',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Admin - Businesses'
            }
          }
        },
        {
          path: 'edit-business/:id',
          component: () => import('@/views/Admin/edit-business.vue'),
          name: 'Edit Business',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Admin - Businesses'
            }
          }
        },
        {
          path: 'apps',
          component: () => import('@/views/Admin/apps.vue'),
          name: 'Apps',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Admin - Applications'
            }
          }
        },
        {
          path: 'api-tokens',
          component: () => import('@/views/Admin/ApiTokens.vue'),
          name: 'API Tokens',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Super Admin - API Tokens'
            }
          }
        }
      ]
    },
    {
      path: '/locations-and-groups',
      component: () => import('@/views/Locations/LocationAndGroups.vue'),
      name: 'LocationsAndGroups',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: true,
          name: 'Locations And Groups',
          icon: 'place'
        }
      },
      children: [
        {
          path: 'locations',
          component: () => import('@/views/Locations/locations.vue'),
          name: 'Locations',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Locations',
              icon: 'place'
            }
          }
        },
        {
          path: 'groups',
          component: () => import('@/views/Locations/Groups.vue'),
          name: 'Location Groups',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Location Groups'
            }
          }
        }
      ]
    },
    {
      path: '/logs',
      component: () => import('@/views/Logs/Logs.vue'),
      name: 'Logs',
      meta: {
        middleware: BackToLogin,
        nav: {
          display: true,
          name: 'Logs',
          icon: 'fas fa-history'
        }
      },
      children: [
        {
          path: 'login-logs',
          component: () => import('@/views/Logs/LoginLogs.vue'),
          name: 'Login Logs',
          meta: {
            middleware: BackToLogin,
            nav: {
              display: false,
              name: 'Login Logs',
              icon: 'fas fa-history'
            }
          }
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pageNotFound.vue'),
      name: 'Page Not Found',
      meta: {
        // middleware: BackToLogin,
      }
    }
  ]
})
export default router
