export default {
  user: (state) => state.user,
  locationsList: (state) => state.locationsList,
  selectedLocations: (state) => state.selectedLocations,
  businessesList: state => state.businessesList,
  activeBusiness: state => state.activeBusiness,
  activeBusinessBrandingAndLogo: (state) => {
    if (state.activeBusinessBranding.branding) {
      return {
        primary: state.activeBusinessBranding.branding.primary_color,
        secondary: state.activeBusinessBranding.branding.secondary_color,
        accent: state.activeBusinessBranding.branding.accent_color,
        logo: state.activeBusinessBranding.branding.logo_url === '' ? require('@/assets/images/uno-logo.png') : state.activeBusinessBranding.branding.logo
      }
    }
    return {
      primary: '#F03C0C',
      secondary: '#333333',
      accent: '#ea9780',
      logo: require('@/assets/images/uno-logo.png')
    }
  }
}
